import React from 'react'
import { Box } from '@mui/material'

const IconBoxed = (props) => (
  <Box
    component='svg'
    width='254'
    height='256'
    viewBox='0 0 254 256'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.59998 51.2C8.59998 27.0641 8.59998 14.9961 16.098 7.49807C23.5961 0 35.6641 0 59.8 0H61.4C85.5359 0 97.6039 0 105.102 7.49807C112.6 14.9961 112.6 27.0641 112.6 51.2V204.8C112.6 228.936 112.6 241.004 105.102 248.502C97.6039 256 85.5359 256 61.4 256H59.8C35.6641 256 23.5961 256 16.098 248.502C8.59998 241.004 8.59998 228.936 8.59998 204.8V51.2Z'
      fill='currentColor'
    />
    <path
      d='M173.646 256C164.485 256 159.904 256 156.184 254.841C148.152 252.338 141.862 246.048 139.359 238.016C138.2 234.296 138.2 229.715 138.2 220.554L138.2 176.246C138.2 167.085 138.2 162.504 139.359 158.784C141.862 150.752 148.152 144.462 156.184 141.959C159.904 140.8 164.485 140.8 173.646 140.8L195.8 140.8C227.612 140.8 253.4 166.588 253.4 198.4C253.4 230.212 227.612 256 195.8 256L173.646 256Z'
      fill='currentColor'
    />
    <path
      d='M253.4 57.6C253.4 89.4116 227.612 115.2 195.8 115.2C163.988 115.2 138.2 89.4116 138.2 57.6C138.2 25.7884 163.988 0 195.8 0C227.612 0 253.4 25.7884 253.4 57.6Z'
      fill='currentColor'
    />
  </Box>
)

export default IconBoxed
