import React, { useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import SnackbarContent from '@mui/material/SnackbarContent'
import WarningIcon from '@mui/icons-material/Warning'
import { Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { openReachabilityModal } from '../../actions/modals'
import { getCurrentEvent } from '../../selectors/event'
import { useRouter } from 'next/router'
import { removeLastGuestMessage } from '../../actions/event'
import useMixpanelTrackEvent from '../../utils/useMixpanelTrackEvent'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  notified: null,
  undo: InfoIcon,
}

const useStyles = makeStyles()((theme) => ({
  success: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  moreInfoButton: {
    color: '#fff',
    minWidth: '90px',
  },
}))

const NotifiedAction = ({ onClose }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const event = useSelector(getCurrentEvent)
  const router = useRouter()

  const onClick = () => {
    if (router.asPath !== `/e/${event.code}`) router.push(`/e/${event.code}`)
    dispatch(openReachabilityModal())
    onClose()
  }

  return (
    <Button className={classes.moreInfoButton} onClick={onClick}>
      {t('moreInfo')}
    </Button>
  )
}

const UndoMessageAction = ({ onClose }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const trackEvent = useMixpanelTrackEvent()

  const onClick = () => {
    trackEvent('Undo Message')
    dispatch(removeLastGuestMessage())
    onClose()
  }

  return (
    <Button className={classes.moreInfoButton} onClick={onClick}>
      {t('undo')}
    </Button>
  )
}

const variantAction = {
  success: null,
  warning: null,
  error: null,
  info: null,
  notified: null,
  undo: UndoMessageAction,
}

const SnackbarContentWrapper = ({
  className,
  message,
  onClose,
  variant,
  ...other
}) => {
  const { classes, cx } = useStyles()
  const Icon = variantIcon[variant]
  const VariantAction = variantAction[variant]

  useEffect(() => {
    if (variant === 'undo') {
      const timeOut = setTimeout(() => {
        onClose()
      }, 3000)
    }
  }, [variant])

  return (
    <SnackbarContent
      className={cx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          {Icon !== null && (
            <Icon className={cx(classes.icon, classes.iconVariant)} />
          )}
          {message}
        </span>
      }
      action={[
        <>
          {variantAction[variant] && (
            <VariantAction key={variant} onClose={onClose} />
          )}
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={onClose}
            size='large'
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </>,
      ]}
      {...other}
    />
  )
}

export default SnackbarContentWrapper
