import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import Header from 'shared-components/common/Header'
import { openSignInModal } from 'actions/modals'
import { getIsMobileOrTablet } from '../../utils/isMobileOrTablet'

const ConnectedHeader = ({ userAvatarUrls, signOut, isSuperAdmin }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const isWidget = router.pathname.includes('/widget/')
  const isMobileApp = useSelector((state) => state.user.isMobileApp)

  // useEffect(() => {
  //   const onEventPage = router.pathname.includes('/e/')
  //   if (onEventPage && window.localStorage && !window.localStorage.getItem('hasSeenBash')) {
  //     setVisible(false)
  //   }
  // }, [router.pathname, visible])

  const signIn = () => {
    if (getIsMobileOrTablet()) {
      window.open(
        'https://go.bash.social/get?utm_source=website&utm_medium=home',
      )
    } else {
      dispatch(openSignInModal())
    }
  }
  // const toHome = () => {
  //   router.push('/home')
  // }
  const toProfile = () => {
    router.push('/me')
  }

  if (isWidget) return null

  if (isMobileApp || router.query.isMobileApp)
    return <div style={{ height: '16px' }} />
  // if (isSuperAdmin) {
  return (
    <Header
      userAvatarUrls={userAvatarUrls}
      signIn={signIn}
      signOut={signOut}
      toProfile={toProfile}
    />
  )
  // }
  // return (
  //   <HeaderOld
  //     userAvatarUrls={userAvatarUrls}
  //     signIn={signIn}
  //     signOut={signOut}
  //     toProfile={toProfile}
  //   />
  // )
}

const mapStateToProps = ({ user }) => ({
  userAvatarUrls: user?.user?.avatarUrls,
  isSuperAdmin: user?.user?.admin?.authorities?.includes('SUPER_ADMIN'),
})

export default connect(mapStateToProps, {})(ConnectedHeader)
