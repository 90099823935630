import React from 'react'
import { Avatar, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Logo from 'svg/logo/IconBoxed'
import { useDispatch } from 'react-redux'

const transformDate = (givenDate) => {
  if (dayjs().subtract(7, 'days').valueOf() > dayjs(givenDate).valueOf()) {
    return dayjs(dayjs()).diff(givenDate, 'week') + 'w'
  } else {
    return dayjs(givenDate).fromNow(true)
  }
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  clickable: {
    cursor: 'pointer',
  },
  text: {
    marginLeft: theme.spacing(2),
  },
  title: {
    marginBottom: 2,
  },
  description: {
    display: 'flex',
  },
  descriptionText: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 5 /* number of lines to show */,
    'line-clamp': 5,
    WebkitBoxOrient: 'vertical',
  },
  descriptionTime: {
    color: `${theme.palette.text.secondary}50`,
    marginLeft: theme.spacing(0.5),
    display: 'inline',
  },
  default: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.main,
  },
  avatar: {
    backgroundColor: theme.palette.grey.main,
  },
}))

const Notification = ({ notification, router }) => {
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const onClick = () => {
    var url = ''
    // if (notification.event) {
    //   url = `/e/${notification.event.code}`
    // } else
    if (notification.clickAction) {
      const splits = notification.clickAction.split('/')
      if (splits.length === 5 && splits[3] === 'users') {
        const userId = splits[4]
        // dispatch(openUserInfo({
        //   id: parseInt(userId)
        // }))
        router.push(`/users/${userId}`)
        return
      }

      url = notification.clickAction
    } else {
      return
    }
    router.push(url)
  }
  const reminderTimeStamp = transformDate(notification.createdAt)
  return (
    <div
      className={cx(classes.root, {
        [classes.clickable]:
          notification.event ||
          notification.otherUser ||
          notification.clickAction,
      })}
      onClick={onClick}
    >
      <Avatar className={classes.avatar} src={notification.iconUrl}>
        <Logo className={classes.default} />
      </Avatar>
      <div className={classes.text}>
        <Typography className={classes.title} variant='subtitle2'>
          {notification.title}
        </Typography>
        <Typography
          component='div'
          className={classes.descriptionText}
          variant='caption'
        >
          {notification.description}
          <Typography className={classes.descriptionTime} variant='caption'>
            {reminderTimeStamp}
          </Typography>
        </Typography>
      </div>
    </div>
  )
}

Notification.propTypes = {}

export default withTranslation('common')(Notification)
