import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import ApiClient from 'shared-components/utils/ApiClient'
import { useDispatch } from 'react-redux'
import Notification from './Notification'
import { useRouter } from 'next/router'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 2, 0, 2),
    overflowY: 'scroll',
    textAlign: 'center',
  },
}))

const PAGE_SIZE = 15

const NotificationsContent = ({}) => {
  const { t } = useTranslation('common')
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [notifications, setNotifications] = useState([])
  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    ApiClient.user
      .getNotifications(0, PAGE_SIZE)
      .then(async (res) => {
        setLoading(false)
        setNotifications(res)
        try {
          const user = await ApiClient.user.getMe()
          dispatch({
            type: 'FETCH_USER_FULFILLED',
            payload: user,
          })
        } catch (e) {}
      })
      .catch((e) => console.log(e))
  }, [setNotifications, dispatch])

  const listInnerRef = useRef()

  const onScroll = () => {
    if (loading) return
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight === scrollHeight) {
        setLoading(true)
        ApiClient.user
          .getNotifications(page, PAGE_SIZE)
          .then(async (res) => {
            setLoading(false)
            setPage(page + 1)
            if (res.length < PAGE_SIZE) {
              setHasMore(false)
            }
            setNotifications([...notifications, ...res])
          })
          .catch((e) => console.log(e))
      }
    }
  }

  return (
    <div
      className={cx(classes.root)}
      onScroll={() => onScroll()}
      ref={listInnerRef}
    >
      {notifications.map((n) => {
        return <Notification key={n.id} notification={n} router={router} />
      })}
      {loading && hasMore && <CircularProgress />}
    </div>
  )
}

export default NotificationsContent
