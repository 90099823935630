// Helper function to get route index from pathname

export default function getIndexFromPath (path) {
  var index = 0
  if (path.startsWith('/public')) {
    index = 1
  } else if (path.startsWith('/me') || path.startsWith('/settings')) {
    index = 2
  }
  return index
}
