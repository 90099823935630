import { GrowthBook } from '@growthbook/growthbook-react'
import mixpanel from '../shared-components/utils/mixpanel'

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-ADuGSNLABsGnFmA2',
  enableDevMode: process.env.NODE_ENV !== 'production',
  trackingCallback: (experiment, result) => {
    mixpanel.track('$experiment_started', { 'Experiment name': experiment.key, 'Variant name': result.key })
  }
})
