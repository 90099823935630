import typeToReducer from 'type-to-reducer'
import { HYDRATE } from 'next-redux-wrapper'

import {
  ADD_FOLLOWING,
  ADD_FRIEND,
  ADD_USER_ORGANISATION,
  FETCH_CATEGORIES,
  FETCH_FOLLOWING,
  FETCH_FRIEND_REQUESTS,
  FETCH_FRIEND_SUGGESTIONS,
  FETCH_FRIENDS,
  FETCH_NOTIFICATION_SETTINGS,
  FETCH_PAST_USER_EVENTS,
  FETCH_PUBLIC_EVENTS,
  FETCH_USER,
  FETCH_USER_EVENTS,
  FETCH_USER_ORGANISATIONS,
  FETCH_USER_PROFILE,
  INCREMENT_IMAGE_VERSION,
  LOGOUT_USER,
  PATCH_NOTIFICATION_SETTINGS,
  PATCH_USER,
  REMOVE_FOLLOWING,
  REMOVE_FRIEND,
  REMOVE_FRIEND_REQUEST,
  REMOVE_FRIEND_SUGGESTION,
  RSVP_FOLLOWING_EVENT,
  SET_HAS_AVATAR,
  UPLOAD_USER_AVATAR,
  FETCH_USER_BY_CODE,
  SET_FROM_USER_CODE,
  REMOVE_FROM_USER,
  FETCH_USER_BACKGROUND,
  CREATED_ORGANISATION,
  SET_IS_MOBILE_APP,
} from 'actions/user'

export const eventReducer = (key) => ({
  PENDING: (state, action) => ({
    ...state,
    [key]: {
      ...state[key],
      isFulfilled: false,
      isRejected: false,
      error: null,
    },
  }),
  FULFILLED: (state, action) => ({
    ...state,
    [key]: {
      ...state[key],
      isFulfilled: true,
      isRejected: false,
      events: action.payload.map((event) => event.code),
    },
  }),
  REJECTED: (state, action) => ({
    ...state,
    [key]: {
      ...state[key],
      isFulfilled: false,
      isRejected: true,
      error: action.payload,
    },
  }),
})

export const basicReducer = (key) => ({
  PENDING: (state, action) => ({
    ...state,
    [key]: {
      ...state[key],
      isFulfilled: false,
      isRejected: false,
      pending: true,
      error: null,
    },
  }),
  FULFILLED: (state, action) => ({
    ...state,
    [key]: {
      ...state[key],
      isFulfilled: true,
      isRejected: false,
      pending: false,
      [key]: action.payload,
    },
  }),
  REJECTED: (state, action) => ({
    ...state,
    [key]: {
      ...state[key],
      isFulfilled: false,
      isRejected: true,
      pending: false,
      error: action.payload,
    },
  }),
})

const initialState = {
  user: {
    weeklyUpdate: true,
  },
  isFulfilled: false,
  isRejected: false,
  pending: false,
  error: null,
  patchPending: false,
  patchError: null,
  uploadingAvatar: false,
  avatarUploadError: null,
  fromUserCode: null,
  following: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    organisations: [],
  },
  profile: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    profile: [],
  },
  events: {
    isFulfilled: false,
    isRejected: false,
    error: null,
    events: [],
  },
  publicEvents: {
    isFulfilled: false,
    isRejected: false,
    error: null,
    events: [],
  },
  pastEvents: {
    isFulfilled: false,
    isRejected: false,
    error: null,
    events: [],
  },
  organisations: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    organisations: [],
    createdOrganisation: null,
  },
  friendSuggestions: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    friendSuggestions: [],
  },
  friendRequests: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    friendRequests: [],
  },
  friends: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    friends: [],
  },
  sharedByUser: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    sharedByUser: null,
  },
  notificationSettings: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    notificationSettings: null,
  },
  categories: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    notificationSettings: null,
  },
  imageVersion: 0,
}

export default typeToReducer(
  {
    [HYDRATE]: (state, action) =>
      action.payload.user.user.id
        ? {
            ...state,
            isFulfilled: true,
            user: action.payload.user.user ?? {},
          }
        : state,
    [FETCH_USER]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        isRejected: false,
        pending: true,
        error: null,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        isFulfilled: true,
        isRejected: false,
        pending: false,
        user: action.payload ?? {},
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        pending: false,
        error: action.payload,
      }),
    },
    [FETCH_USER_BACKGROUND]: {
      PENDING: (state, action) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        isFulfilled: true,
        isRejected: false,
        pending: false,
        user: action.payload ?? {},
      }),
      REJECTED: (state, action) => ({
        ...state,
      }),
    },
    [SET_IS_MOBILE_APP]: (state, action) => ({
      ...state,
      isMobileApp: action.payload,
    }),
    [LOGOUT_USER]: () => ({
      ...initialState,
      isRejected: true,
    }),
    [INCREMENT_IMAGE_VERSION]: (state, action) => ({
      ...state,
      imageVersion: state.imageVersion + 1,
    }),
    [REMOVE_FOLLOWING]: (state, action) => ({
      ...state,
      following: {
        ...state.following,
        organisations: state.following.organisations.filter(
          (o) => o.id !== action.payload,
        ),
      },
    }),
    [REMOVE_FRIEND]: (state, action) => ({
      ...state,
      friends: {
        ...state.friends,
        friends: state.friends.friends.filter((f) => f.id !== action.payload),
      },
    }),
    [ADD_FRIEND]: (state, action) => ({
      ...state,
      friends: {
        ...state.friends,
        friends: [...state.friends.friends, ...action.payload],
      },
    }),
    [ADD_USER_ORGANISATION]: (state, action) => ({
      ...state,
      organisations: {
        ...state.organisations,
        organisations: [
          ...state.organisations.organisations,
          {
            state: 'ACTIVE',
            role: 'ADMIN',
            organisation: action.payload,
          },
        ],
      },
    }),
    [CREATED_ORGANISATION]: (state, action) => ({
      ...state,
      organisations: {
        ...state.organisations,
        createdOrganisation: action.payload,
      },
    }),
    [REMOVE_FRIEND_SUGGESTION]: (state, action) => ({
      ...state,
      friendSuggestions: {
        ...state.friendSuggestions,
        friendSuggestions: state.friendSuggestions.friendSuggestions.filter(
          (f) => f.id !== action.payload,
        ),
      },
    }),
    [REMOVE_FRIEND_REQUEST]: (state, action) => ({
      ...state,
      friendRequests: {
        ...state.friendRequests,
        friendRequests: state.friendRequests.friendRequests.filter(
          (f) => f.id !== action.payload.id,
        ),
      },
    }),
    [ADD_FOLLOWING]: (state, action) => ({
      ...state,
      following: {
        ...state.following,
        organisations: [...state.following.organisations, action.payload],
      },
    }),
    [SET_FROM_USER_CODE]: (state, action) => ({
      ...state,
      fromUserCode: action.payload,
    }),
    [REMOVE_FROM_USER]: (state, action) => ({
      ...state,
      sharedByUser: {
        ...state.sharedByUser,
        sharedByUser: null,
      },
    }),
    [SET_HAS_AVATAR]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        hasAvatar: action.payload,
      },
    }),
    [FETCH_FOLLOWING]: {
      PENDING: (state, action) => ({
        ...state,
        following: {
          ...state.following,
          isFulfilled: false,
          isRejected: false,
          pending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        following: {
          ...state.following,
          isFulfilled: true,
          isRejected: false,
          pending: false,
          organisations: action.payload,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        following: {
          ...state.following,
          isFulfilled: false,
          isRejected: true,
          pending: false,
          error: action.payload,
        },
      }),
    },
    [FETCH_FOLLOWING]: {
      PENDING: (state, action) => ({
        ...state,
        following: {
          ...state.following,
          isFulfilled: false,
          isRejected: false,
          pending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        following: {
          ...state.following,
          isFulfilled: true,
          isRejected: false,
          pending: false,
          organisations: action.payload,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        following: {
          ...state.following,
          isFulfilled: false,
          isRejected: true,
          pending: false,
          error: action.payload,
        },
      }),
    },
    [FETCH_FRIENDS]: basicReducer('friends'),
    [FETCH_USER_BY_CODE]: basicReducer('sharedByUser'),
    [FETCH_USER_PROFILE]: basicReducer('profile'),
    [FETCH_PUBLIC_EVENTS]: eventReducer('publicEvents'),
    [FETCH_USER_EVENTS]: eventReducer('events'),
    [FETCH_PAST_USER_EVENTS]: eventReducer('pastEvents'),
    [FETCH_USER_ORGANISATIONS]: basicReducer('organisations'),
    [FETCH_FRIEND_SUGGESTIONS]: basicReducer('friendSuggestions'),
    [FETCH_FRIEND_REQUESTS]: basicReducer('friendRequests'),
    [FETCH_NOTIFICATION_SETTINGS]: basicReducer('notificationSettings'),
    [FETCH_CATEGORIES]: basicReducer('categories'),
    [PATCH_NOTIFICATION_SETTINGS]: basicReducer('notificationSettings'),
    [UPLOAD_USER_AVATAR]: {
      PENDING: (state) => ({
        ...state,
        uploadingAvatar: true,
        avatarUploadError: null,
      }),
      FULFILLED: (state) => ({
        ...state,
        uploadingAvatar: false,
        user: {
          ...state.user,
          avatarUrls: state.user.avatarUrls.map(
            (url) => url + `?imHash=${Date.now()}`,
          ),
          avatarUrl: `${state.user.avatarUrl}?imHash=${Date.now()}`,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        uploadingAvatar: false,
        error: action.payload,
      }),
    },
    [PATCH_USER]: {
      PENDING: (state, action) => ({
        ...state,
        patchPending: true,
        patchError: null,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        patchPending: false,
        user: action.payload ?? {},
      }),
      REJECTED: (state, action) => ({
        ...state,
        patchPending: false,
        patchError: action.payload,
      }),
    },
    [RSVP_FOLLOWING_EVENT]: {
      PENDING: (state, action) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        publicEvents: {
          ...state.publicEvents,
          events: state.publicEvents.events.map((event) => {
            if (event.id === action.payload.id) {
              event.myGuest = action.payload.myGuest
              return event
            } else {
              return event
            }
          }),
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
      }),
    },
  },
  initialState,
)
