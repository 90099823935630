import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Avatar, Button, CircularProgress } from '@mui/material'

import { ExpandLess } from '@mui/icons-material'
import scraperApiClient from '../../../src/scraper/ScraperApiClient'
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import dayjs from 'dayjs'

const queryClient = new QueryClient()

const useStyles = makeStyles()((theme) => ({
  root: {},
  row: {
    borderTop: '1px solid ' + theme.palette.grey[200],
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 24,
    height: 24,
  },
  progress: {
    marginLeft: theme.spacing(2),
  },
}))

const ScraperProgressView = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ScraperProgress />
    </QueryClientProvider>
  )
}

const ScraperProgress = ({ initialScraper }) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const router = useRouter()
  const [expanded, setExpanded] = React.useState(false)

  const { status, data } = useQuery(
    ['scraper_progress'],
    async () => {
      const result = await scraperApiClient.scrapers.getRunningScrapers()
      if (JSON.stringify(result) !== JSON.stringify(data)) {
        setExpanded(true)
      }
      return result
    },
    { refetchInterval: 5000 },
  )

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0',
        right: 40,
        width: '40vw',
        zIndex: 100,
        borderTop: '1px solid #2E60DC',
        borderRight: '1px solid #2E60DC',
        borderLeft: '1px solid #2E60DC',
      }}
    >
      <Accordion
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded)
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandLess />}
          aria-controls='panel1-content'
          id='panel1-header'
          style={{
            backgroundColor: '#F2F5FD',
            width: '100%',
            paddingRight: 16,
            paddingLeft: 16,
          }}
        >
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: '700',
              width: '100%',
            }}
          >
            In progress · {data?.length ?? 0} items
          </Typography>
        </AccordionSummary>
        {data?.map((row) => {
          return (
            <div
              onClick={() => {
                setExpanded(false)
                router.push(`/admin/pages/${row.id}/scrape-logs`)
              }}
              className={classes.row}
              key={row.id}
            >
              <div className={classes.flex}>
                <Avatar
                  className={classes.avatar}
                  sx={{ width: 24, height: 24 }}
                  alt={row.name}
                  src={row.eventDefaults?.organisation?.avatar?.urls.lg}
                />
                <Typography
                  style={{
                    marginLeft: 8,
                  }}
                  variant='body2'
                >
                  {row.name}
                </Typography>
              </div>
              {(row._count.tasks ?? 0) < 1 && (
                <div className={classes.flex}>
                  {row.tasks?.some((e) => {
                    return (
                      dayjs(e.createdAt).diff(dayjs(row.lastRunAt), 'hours') < 1
                    )
                  }) && (
                    <Typography variant='body2'>Some tasks failed</Typography>
                  )}
                  {!row.tasks?.some((e) => {
                    return (
                      dayjs(e.createdAt).diff(dayjs(row.lastRunAt), 'hours') < 1
                    )
                  }) && (
                    <CircularProgress size={16} className={classes.progress} />
                  )}
                </div>
              )}
              {(row._count.tasks ?? 0) > 0 && (
                <div className={classes.flex}>
                  <Typography variant='body2'>
                    {row._count.tasks ?? 0} pending
                  </Typography>
                  <Button
                    onClick={() => {
                      setExpanded(false)
                      router.push(`/admin/pages/${row.id}/review`)
                    }}
                    color='primary'
                    variant='text'
                  >
                    Review
                  </Button>
                </div>
              )}
            </div>
          )
        })}
      </Accordion>
    </div>
  )
}

export default ScraperProgressView
